<template>
  <div v-if="category" :class="{ 'two-items-flex-container': isPda && $screen.width > 640 }">
    <div
      class="left-right-margins hasBorderBottom"
      :class="{'flex-item-42 hasDarkerBorderBottom': isPda && $screen.width > 640,
               'max-width-42': isPda && $screen.width > 1285,
               'flex-item-27': isPda && $screen.width > 1500,
               'max-width-47': isPda && $screen.width <= 1285 && $screen.width > 640 }"
      v-for="item in availableProducts"
      v-bind:key="item.id"
    >
      <MenuItem
        :isCategoryOpen="isCategoryOpen"
        :item="item"
        :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
        :membershipPricesVisible="membershipPricesVisible"
        :isInteractiveCatalogue="isInteractiveCatalogue"
        :userHasMembership="userHasMembership"
        :storeIsHotel="storeIsHotel"
        :hideDescription="hideDescription"
        :hidePhotos="hidePhotos"
      ></MenuItem>
    </div>
    <div
      v-if="unavailableProducts.length > 0 && !clickedToSeeUnavailable"
      class="left-right-margins"
      :class="{'flex-item-42': isPda && $screen.width > 640,
               'max-width-42': isPda && $screen.width > 1285,
               'flex-item-27': isPda && $screen.width > 1500,
               'max-width-47': isPda && $screen.width <= 1285 && $screen.width > 640 }"
    >
      <div class="unavailable-items-container" @click="showUnavailableItems">
        <div class="unavailable-items-button">
          <div class="unaivailble-items-title">
            <h3>{{unavailableProducts.length}} {{labels.menu.unavailanbleItems[language]}}</h3>
            <h4
              v-if="!isCategoryOpen"
            >{{labels.menu.servingHours[language]}} {{category.startTime.slice(0, -3)}}-{{category.endTime.slice(0, -3)}}</h4>
          </div>
          <div class="unaivailble-items-description">
            <div>{{labels.menu.seeUnavailableItems[language]}}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="unavailableProducts.length > 0 && clickedToSeeUnavailable"
      :class="{ 'two-items-flex-container-unavailable': isPda && $screen.width > 640 }"
    >
      <div
        class="left-right-margins hasBorderBottom"
        :class="{'flex-item-42': isPda && $screen.width > 640,
                 'max-width-42': isPda && $screen.width > 1285,
                 'flex-item-27': isPda && $screen.width > 1500,
                 'max-width-47': isPda && $screen.width <= 1285 && $screen.width > 640 }"
        v-for="item in unavailableProducts"
        v-bind:key="item.id"
      >
        <MenuItem
          :isCategoryOpen="isCategoryOpen"
          :item="item"
          :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
          :membershipPricesVisible="membershipPricesVisible"
          :storeIsHotel="storeIsHotel"
          :happyHourDiscountPercentage="happyHourDiscountPercentage"
          :isInHappyHour="isInHappyHour"
          :hideDescription="hideDescription"
          :hidePhotos="hidePhotos"
        ></MenuItem>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  name: "CategoryProducts",
  components: {
    MenuItem,
  },
  data() {
    return {
      clickedToSeeUnavailable: false,
    };
  },
  props: ["category", "membershipEnabledForRestaurant", "membershipPricesVisible", "userHasMembership", "storeIsHotel", "happyHourDiscountPercentage", "isInHappyHour", "currentGreekDate", "isInteractiveCatalogue", "isPda", "hideDescription", "hidePhotos"],
  computed: {
    availableProducts() {
      if (!this.isCategoryOpen) {
        return [];
      };
      return this.category.products.filter((product) => product.available);
    },

    unavailableProducts() {
      if (!this.isCategoryOpen) {
        return this.category.products;
      }
      return this.category.products.filter((product) => !product.available);
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    isCategoryOpen() {
      if (!this.category.startTime || !this.category.endTime || this.currentGreekDate == '' || this.currentGreekDate == undefined) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isCategoryOpen = true;

      if (this.category.startTime < this.category.endTime) {
        isCategoryOpen =
          this.category.startTime < currentTime &&
          this.category.endTime > currentTime;
      } else {
        isCategoryOpen =
          this.category.startTime < currentTime ||
          this.category.endTime > currentTime;
      }

      return isCategoryOpen;
    },
  },
  methods: {
    showUnavailableItems() {
      this.clickedToSeeUnavailable = true;
    },
  },
};
</script>

<style scoped>
.hasBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
}

.hasDarkerBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.4) !important;
}

.left-right-margins {
  margin-left: 4%;
  margin-right: 4%;
}

.two-items-flex-container {
  display: flex;
  flex-wrap: wrap;
}

.two-items-flex-container-unavailable {
  display: flex;
  flex-wrap: wrap;
  min-width: 50%;
  width: 100%;
}

.flex-item-42 {
  flex: 42%;
}

.max-width-42 {
  max-width: 42%;
}

.flex-item-27 {
  flex: 27%;
  max-width: 27%;
  margin-left: 3% !important;
  margin-right: 3% !important;
}

.max-width-47 {
  max-width: 47%;
}

.unavailable-items-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2% 0 2% 0;
  cursor: pointer;
  z-index: 1;
  background-color: rgba(180, 180, 180, 0.2);
  padding-left: 5px;
  border-radius: 10px;
}

.unavailable-items-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #414551;
  font-weight: 500;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.unavailable-items {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background-color: #bdbdbd;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
}

.unaivailble-items-title > h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.unaivailble-items-title > h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.unaivailble-items-description {
  color: gray;
  font-size: 13px;
  font-weight: 400;
  padding-right: 15%;
  white-space: pre-wrap;
}

@media screen and (max-width: 1285px) {
  .unavailable-items-container {
    margin: 4% 0 4% 0;
  }

  .unavailable-items-button {
    padding-right: 3%;
    padding-left: 1%;
  }

  .unavailable-items {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h3 {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h4 {
    font-size: 13px;
    font-weight: 500;
    padding-right: 5px;
  }

  .unaivailble-items-description {
    font-size: 12px;
    font-weight: normal;
  }
  .left-right-margins {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}
</style>