<template>
  <div @click="onCategoryClick" :id="`${category.anchor}-id`" class="food-category">
    <div :class="{ selectedCategory: category.isActive }" id="category">
      <p>{{ getCategoryName(category)}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoodCategory",
  components: {},
  computed: {
    areMobileCategoriesOpen() {
      return this.$store.getters.getAreMobileCategoriesOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    }
  },

  data() {
    return {};
  },
  props: ["category"],
  methods: {
    getCategoryName(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].name != null && category.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].name;
      }
      return category.names.translation[this.language].name;
    },
    onCategoryClick: function () {
      const yOffset = -50;
      const y =
        document.getElementById(this.category.anchor).getBoundingClientRect()
          .top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y });

      if (!this.category.isActive) {
        this.$store.dispatch("goToCategory", this.category);
      }
    },
  },
};
</script>

<style scoped>
.food-category {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 1vh;
  color: rgb(88, 88, 88);
  font-size: 15px;
  font-weight: 200;
  margin: 4% 0% 3% 2%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-right: 2px;
  padding-left: 2px;
  background-color: #f3f3f3;
  border-radius: 10px;
}

p {
  display: inline;
}

#category {
  height: 100%;
  font-weight: 600;
  margin-right: 10px;
}

.selectedCategory {
  color: #ed5005;
  font-size: 16px;
  font-weight: 800 !important;
}

.redBorder {
  border: 1px solid #198b4a;
  box-shadow: 2px 2px 4px rgba(93, 92, 99, 0.16),
    2px 2px 4px rgba(93, 92, 99, 0.23);
}

@media screen and (max-width: 800px) {
  .food-category {
    width: 80%;
    font-size: 15px;
    margin-right: 10vw;
    margin-bottom: -3%;
  }

  #category {
    margin-left: 5vw;
  }
}
</style>



