<template>
  <div class="non-semantic-protector">
    <h1 class="ribbon">
      <strong class="ribbon-content">
        <span class="fluter-giveaway">{{ labels.contest.ribbon.giveAwayFromFluter[language] }}</span>
        <br />
        <span>{{ labels.contest.ribbon.orderAndWin[language] }}</span>
        <br />
        <span>{{ labels.contest.ribbon.clickEnterFluter[language] }}</span>
        <br />
        <span class="terms-here">
          {{ labels.contest.ribbon.terms[language] }}
          <a
            href="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/contest_terms.jpg"
            target="_blank"
          >{{ labels.contest.modal.here[language] }}</a>
        </span>
      </strong>
    </h1>
  </div>
</template>

<script>
export default {
  name: "ContestRibbon",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {},
};
</script>

<style scoped>
.non-semantic-protector {
  position: relative;
  width: 100%;
  z-index: 1;
}

.ribbon {
  font-size: 16px !important;
  letter-spacing: 1px;
  width: 75%;

  position: relative;
  background: #05cf6d;
  /* background: linear-gradient(62deg, #198b4a, #04da6f); */
  color: #003340;
  text-align: center;
  padding: 1em 2em; /* Adjust to suit */
  margin: 2em auto 2em;
  margin-top: 5vh;
  margin-bottom: calc(5vh + 2.7em);
}

.terms-here {
  font-size: 14px !important;
}

.fluter-giveaway {
  font-size: 18px !important;
}

@media (max-width: 800px) {
  .ribbon {
    font-size: 14px !important;
    width: 85%;
    margin-top: 1vh;
    margin-bottom: calc(3vh + 1em);
  }

  .terms-here {
    font-size: 12px !important;
  }

  .fluter-giveaway {
    font-size: 16px !important;
  }
}

@media (max-width: 400px) {
  .ribbon {
    font-size: 13px !important;
  }

  .fluter-giveaway {
    font-size: 15px !important;
  }

  .terms-here {
    font-size: 11px !important;
  }
}

@media (max-width: 340px) {
  .ribbon {
    font-size: 11px !important;
  }

  .fluter-giveaway {
    font-size: 12px !important;
  }

  .terms-here {
    font-size: 10px !important;
  }
}

@media (max-width: 300px) {
  .ribbon {
    font-size: 10px !important;
  }

  .fluter-giveaway {
    font-size: 11px !important;
  }

  .terms-here {
    font-size: 9px !important;
  }
}
</style>