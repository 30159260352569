<template>
  <div class="non-semantic-protector">
    <h1 class="ribbon">
      <strong class="ribbon-content">
        <span v-if="numberOfServices == 1">
          {{
          labels.happyHour.ribbon.theStore[language]
          }}
        </span>
        <span v-else>{{ labels.happyHour.ribbon.theService[language] }}</span>
        <span v-if="numberOfServices == 1">"{{ storeNames.translation[language].name }}"</span>
        <span v-else>"{{ serviceNames.translation[language].name }}"</span>
        <span v-if="numberOfServices > 1">{{ labels.happyHour.ribbon.service[language] }}</span>
        {{ labels.happyHour.ribbon.isInHappyHour[language] }}
        {{ labels.happyHour.ribbon.everythingOff[language]
        }}{{ happyHourDiscountPercentage }}%!
        <br />
        {{
        labels.happyHour.ribbon.offerAppliesFrom[language]
        }}
        {{ happyHourStartTime }}
        {{ labels.happyHour.ribbon.until[language] }}
        {{ happyHourEndTime }}
      </strong>
    </h1>
  </div>
</template>

<script>
export default {
  name: "Ribbon",
  props: ["storeNames", "serviceNames", "happyHour", "numberOfServices"],
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },

    happyHourStartTime() {
      return this.$store.getters.getHappyHourStartTime.split(":", 2).join(":");
    },

    happyHourEndTime() {
      return this.$store.getters.getHappyHourEndTime.split(":", 2).join(":");
    },

    happyHourDiscountPercentage() {
      return this.$store.getters.getHappyHourDiscountPercentage;
    },
  },
  methods: {},
};
</script>

<style scoped>
.non-semantic-protector {
  position: relative;
  width: 100%;
  z-index: 1;
}

.ribbon {
  font-size: 16px !important;
  /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */
  letter-spacing: 1px;
  width: 65%;

  position: relative;
  background: #ed5005;
  background: #ff8c47;
  background: linear-gradient(62deg, #e69ba9, #e69ba9);
  background: #fa8072;
  /* background: linear-gradient(62deg, #198b4a, #04da6f); */
  color: #fff;
  text-align: center;
  padding: 1em 2em; /* Adjust to suit */
  margin: 2em auto 4em; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
  margin-top: 5vh;
  /* margin-bottom: calc(9vh + 1em); */
  margin-bottom: calc(5vh + 2.7em);
}
@media (max-width: 1285px) {
  .ribbon {
    font-size: 14px !important;
    width: 75%;
    margin-top: 1vh;
    margin-bottom: calc(6vh + 1em);
  }
}

@media (max-width: 400px) {
  .ribbon {
    font-size: 13px !important;
  }
}

@media (max-width: 340px) {
  .ribbon {
    font-size: 11px !important;
  }
}

@media (max-width: 300px) {
  .ribbon {
    font-size: 10px !important;
  }
}

.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -1em;
  border: 1.7em solid #ed5005;
  border: 1.7em solid #e7803f;
  border: 1.7em solid #fa8072;
  z-index: -1;
}
.ribbon:before {
  left: -3em;
  border-right-width: 2.5em;
  border-left-color: transparent;
}
.ribbon:after {
  right: -3em;
  border-left-width: 2.5em;
  border-right-color: transparent;
}
.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #ac5a50 transparent transparent transparent;
  bottom: -1em;
}
.ribbon .ribbon-content:before {
  left: 0;
  border-width: 1.2em 0 0 1.2em;
}
.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1.2em 1.2em 0 0;
}
</style>