

<template>
  <div id="choose-category">
    <div id="label">
      <p>{{ labels.categories.categoriesHeader[language] }}</p>
    </div>
    <font-awesome-icon icon="utensils" />
  </div>
</template>

<script>
export default {
  name: "ChooseCategory",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
#choose-category {
  background: linear-gradient(62deg, #141414, #3b3838);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  color: white;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.8px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

p {
  /* height: 100%; */
  display: inline;
  align-items: center;
  justify-content: center;
  /* background: chartreuse; */
}

#label {
  height: 100%;
}

#checkbox {
  box-shadow: 0 0 1px 3px rgba(93, 92, 99, 0.05);
  border: 1px solid rgba(116, 135, 150, 0.23);
  background: white;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;

  cursor: pointer;
}

#some-id {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#category {
  height: 100%;
  font-weight: 600;
}

.whiteBackground {
  background: white !important;
}

.selectedCheckbox {
  background: #198b4a !important;
  /* padding: 20px !important; */
}

.redBorder {
  border: 1px solid #198b4a;
}
</style>



