<template>
  <div id="contest-modal-container">
    <div id="contest-modal">
      <div @click="onCloseClick" id="close-contest-modal">
        <font-awesome-icon id="close-icon" icon="times" />
      </div>
      <div id="fluter-contest">
        <h4>{{ labels.contest.modal.giveAwayFromFluter[language] }}</h4>
      </div>
      <div id="contest-time">
        <h4>{{ labels.contest.modal.orderAndWin[language] }}</h4>
      </div>
      <img id="happy-image" src="../assets/happy-cheese.png" alt />
      <div id="contest-explanation">
        <h4>
          {{ labels.contest.modal.contestExplanation[language] }}
          <a
            href="https://fluter.fra1.cdn.digitaloceanspaces.com/assets/contest_terms.jpg"
            target="_blank"
          >{{ labels.contest.modal.here[language] }}</a>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContestModal",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    }
  },
  methods: {
    onCloseClick: function (event) {
      this.$store.dispatch("closeContestModal");
    },
  },
};
</script>

<style scoped>
#contest-modal-container {
  position: fixed;
  width: 100vw;
  top: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 205;
}

#close-icon {
  width: 16px;
  height: 16px;
}

#contest-modal {
  width: 40vw;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  overflow-y: scroll;

  opacity: 1;
  border-radius: 20px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#contest-modal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#close-contest-modal {
  display: flex;
  background: white;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 10px;
  padding-right: 15px;
}

#fluter-contest {
  display: flex;
  background: white;
  width: 100%;
  justify-content: center;
  margin-top: 0px;
}

#fluter-contest > h4 {
  font-size: 22px;
  font-weight: bold;
  color: #ff8c47;
  letter-spacing: 1px;
  text-decoration: underline;
  text-align: center;
}

#contest-time,
#contest-explanation {
  width: 90%;
  height: 20%;
}

#contest-time > h4 {
  font-size: 22px;
  font-weight: bold;
  color: #198b4a;
  letter-spacing: 1px;
  text-align: center;
}

#contest-explanation > h4 {
  font-size: 17px;
  font-weight: 600;
  color: gray;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  /* margin-top: 3%; */
}

#happy-image {
  height: 30vh;
  min-height: 150px;
  /* height: 200px; */
  width: auto;
}

@media (max-width: 800px) {
  #contest-modal {
    width: 90vw;
    /* left: 5vw; */
    /* top: 25vh; */
  }

  #contest-time > h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  #fluter-contest > h4 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
  }

  #contest-explanation > h4 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    /* margin-top: 3%; */
  }

  #happy-image {
    height: 15vh;
  }
}
</style>