<template>
  <div :class="{ disabled: !item.available || !isCategoryOpen }" @click="onAddItem" id="menu-item">
    <div
      id="title-n-description"
      :class="{ smallerWidth: item.photoLink != undefined && item.photoLink != null && item.photoLink != '' && !hideImage }"
    >
      <div :class="{ crossOut: !item.available || !isCategoryOpen }">
        <h3
          class="menu-item-title"
          :class="{whiteSpaceNoWrap: shouldNoWrap, 'margin-bottom-8': !item.priceable}"
        >{{getItemName(item)}}</h3>
      </div>
      <div
        :class="{
          'no-image-description-width':
            hideImage || item.photoLink == undefined,
          'margin-bottom-1': item.priceable
        }"
        id="description"
      >
        <v-clamp
          v-if=" !hideDescription && ((item.names && item.names.translation[language].details!=null && item.names.translation[language].details.trim().length!=0) || (selectedExtraLanguage!=null && item.names && item.names.translation[selectedExtraLanguage] && item.names.translation[selectedExtraLanguage].details!=null && item.names.translation[selectedExtraLanguage].details.trim().length!=0))"
          :max-lines="2"
        >{{getItemDetails(item)}}</v-clamp>
      </div>
      <div id="price" v-if="item.priceable">
        <h3>
          {{ userHasMembership && itemHasMemberShipPrice ? membershipCost : cost }}€
          <font-awesome-icon
            v-if="itemHasMembershipPriceHidden || (userHasMembership && itemHasMemberShipPrice)"
            icon="home"
            style="margin-left: 4px; color: #737373;"
          />
        </h3>
      </div>
      <div v-if="shouldShowMembershipPrice && item.priceable" id="membership-price">
        <h3 v-if="storeIsHotel">{{ labels.menu.allInclusivePrice[language] + membershipCost }}€</h3>
        <h3 v-if="!storeIsHotel">{{ labels.menu.memberShipPrice[language] + membershipCost }}€</h3>
        <h3
          v-if="!storeIsHotel"
          style="padding-top: 1px"
        >{{ labels.menu.onlyForFluterOrders[language]}}</h3>
        <h3
          v-if="!storeIsHotel"
          style="padding-top: 1px"
        >{{ labels.menu.askStoreForMembershipInfo[language]}}</h3>
      </div>
    </div>
    <div
      v-if="item.photoLink != undefined && item.photoLink != null && item.photoLink != '' && !hideImage && !hidePhotos"
      class="image-container"
    >
      <img
        v-if="item.photoLink != undefined && item.photoLink != null && item.photoLink.trim().length!=0 && item.photoLink.endsWith('.gif')"
        class="item-img"
        :src="`${item.photoLink}`"
      />
      <vue-load-image
        v-else-if="item.photoLink != undefined & item.photoLink != null && item.photoLink.trim().length!=0 && !item.photoLink.endsWith('.gif')"
      >
        <img slot="image" :src="`${item.photoLink}`" class="item-img" />
        <img slot="preloader" src="../assets/images/loading_img.gif" class="item-img" />
      </vue-load-image>
      <button
        v-if="isInteractiveCatalogue"
        class="bottom-right-to-img-plus plus-btn-to-cart"
        type="button"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class
          width="100%"
          height="100%"
        >
          <path
            fill="currentColor"
            d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
          />
        </svg>
      </button>
    </div>
    <div v-else-if="isInteractiveCatalogue" class="image-plus-container">
      <button class="center-to-item-plus plus-btn-to-cart" type="button">
        <svg
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class
          width="100%"
          height="100%"
        >
          <path
            fill="currentColor"
            d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>


<script>
import VClamp from "vue-clamp";
import VueLoadImage from 'vue-load-image';

export default {
  name: "MenuItem",
  components: {
    VClamp,
    'vue-load-image': VueLoadImage
  },
  computed: {
    cost() {
      let firstAggregatedSubCategory = this.item.subselections.find(subselection => subselection.priceAggregated);
      if (firstAggregatedSubCategory) {
        return (firstAggregatedSubCategory.options[0].price + this.item.price).toFixed(2);
      } else {
        return this.item.price.toFixed(2);
      }

    },

    shouldNoWrap() {
      if (this.item.photoLink) {
        if (this.item && this.item.names && this.item.names.translation[this.language] && this.item.names.translation[this.language].name && this.item.names.translation[this.language].name.length <= 20) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.item && this.item.names && this.item.names.translation[this.language] && this.item.names.translation[this.language].name && this.item.names.translation[this.language].name.length <= 28) {
          return true;
        } else {
          return false;
        }
      }

    },

    membershipCost() {
      return this.item.membershipPrice.toFixed(2);
    },

    itemHasMemberShipPrice() {
      return this.membershipEnabledForRestaurant && this.item.memberPriceEnabled && this.item.price != this.item.membershipPrice
    },

    //we show the extra membership price only to the non member users
    //the member users when they log in they see as the regular price the membership price 
    shouldShowMembershipPrice() {
      return this.membershipEnabledForRestaurant && this.membershipPricesVisible && this.item.memberPriceEnabled && this.item.price != this.item.membershipPrice && !this.userHasMembership;
    },

    itemHasMembershipPriceHidden() {
      return this.membershipEnabledForRestaurant && !this.membershipPricesVisible && this.item.memberPriceEnabled && this.item.price != this.item.membershipPrice;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    selectedExtraLanguage() {
      return this.$store.getters.getSelectedExtraLanguage;
    },
  },
  props: ["item", "membershipEnabledForRestaurant", "membershipPricesVisible", "userHasMembership", "storeIsHotel", "isCategoryOpen", "happyHourDiscountPercentage", "isInteractiveCatalogue", "hideDescription", "hidePhotos"],
  data() {
    return {
      hideImage: false,
    };
  },
  methods: {
    getItemName(item) {
      if (this.selectedExtraLanguage != null && item.names.translation[this.selectedExtraLanguage] && item.names.translation[this.selectedExtraLanguage].name != null && item.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return item.names.translation[this.selectedExtraLanguage].name;
      }
      return item.names.translation[this.language].name;
    },
    getItemDetails(item) {
      if (this.selectedExtraLanguage != null && item.names.translation[this.selectedExtraLanguage] && item.names.translation[this.selectedExtraLanguage].details != null && item.names.translation[this.selectedExtraLanguage].details.trim().length != 0) {
        return item.names.translation[this.selectedExtraLanguage].details;
      }
      return item.names.translation[this.language].details;
    },
    onAddItem: async function () {
      if (this.item.available && this.isCategoryOpen) {
        let item = JSON.parse(JSON.stringify(this.item))
        item.subselections.forEach(subselection => {
          subselection.options.forEach(option => {
            if (option.defaultSelected) {
              option.isSelected = true;
            } else {
              option.isSelected = false
            }
          })
          if (subselection.mandatory) {
            subselection.options[0].isSelected = true;
          }
        })

        this.$store.dispatch("setSelectedItem", item);
        this.$store.dispatch("setItemModalTrigger", "MenuItem");

        let modal = document.getElementById("modal");
        if (modal != null) {
          modal.scrollTop = 0;
        }

        this.$store.dispatch("openItemModal");
      }
    },

    showNoImage: function () {
      this.hideImage = true;
    },
  },
};
</script>

<style scoped>
#menu-item {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0 2% 0;
  cursor: pointer;
  z-index: 1;
}

#title-n-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  margin-top: 1vh;
}

.smallerWidth {
  width: 75%;
}

@media screen and (max-width: 1285px) {
  #title-n-description {
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 2px;
  }
}

.menu-item-title {
  font-family: "Fluter-font-bold" !important;
  color: rgb(20, 20, 20);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 4px;
}

#description {
  font-family: "Fluter-font" !important;
  color: rgb(112, 112, 112);
  font-size: 14px;
  font-weight: 500;
  padding-right: 8%;
  white-space: pre-wrap;
}

.no-image-description-width {
  padding-right: 0 !important;
}
.margin-bottom-1 {
  margin-bottom: 1px;
}

.margin-bottom-8 {
  margin-bottom: 8px !important;
}

#price {
  font-family: "Fluter-font" !important;
  display: flex;
  color: rgb(20, 20, 20);
}

#membership-price {
  font-family: "Fluter-font" !important;
  display: flex;
  flex-direction: column;
}

#price > h3 {
  font-size: 14px;
  font-weight: 500;
  padding-top: 10%;
  margin: 0 10px 0 0;
}

#membership-price > h3 {
  color: #188e4b;
  font-size: 14px;
  font-weight: 500;
  padding-top: 3%;
  margin: 0 10px 0 0;
  font-style: italic;
}

.discounted {
  color: #198b4a !important;
  font-weight: 700 !important;
}

.image-container {
  position: relative;
  margin: 0px 0px 0px 0px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  width: 112px;
  height: 98px;
}

.image-plus-container {
  position: relative;
  margin: 0px 0px 0px 0px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  width: 30px;
  height: 30px;
}

.item-img {
  width: 112px;
  aspect-ratio: auto 112 / 98;
  height: 98px;
  box-sizing: border-box;
  object-fit: cover;
  object-position: center center;
  z-index: 0;
  margin: 0px auto;
  border-radius: 8px;
  max-width: 100%;
}

.bottom-right-to-img-plus {
  position: absolute;
  z-index: 1;
  right: -0.4rem;
  bottom: -0.5rem;
}

.center-to-item-plus {
  position: absolute;
  right: -0.4rem;
  z-index: 1;
}

.plus-btn-to-cart {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  color: rgb(114, 114, 114);
  background-color: rgb(243, 243, 243);
  border: rgb(243, 243, 243);

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 8px;
}

.crossOut {
  text-decoration-line: line-through;
}

.crossOut > h3 {
  color: gray !important;
}

.disabled {
  cursor: not-allowed !important;
  background-color: rgba(180, 180, 180, 0.2);
  padding: 5px;
  border-radius: 10px;
  min-height: 80%;
  width: 101% !important;
}

.grayOut {
  filter: grayscale(75%);
}

.whiteSpaceNoWrap {
  white-space: nowrap;
}

/* responsive shit */

@media screen and (max-width: 1285px) {
  #menu-item {
    margin: 4% 0 4% 0;
  }

  .menu-item-title {
    padding-right: 5px;
  }

  #name-and-description {
    padding-right: 5%;
  }
}

@media screen and (max-width: 753px) {
  .menu-item-title {
    font-size: 0.94rem;
  }

  #description {
    font-size: 0.868rem;
  }

  #price > h3,
  #membership-price > h3 {
    font-size: 0.88rem;
  }
}
</style>
