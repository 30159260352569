var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('div',{class:{ 'two-items-flex-container': _vm.isPda && _vm.$screen.width > 640 }},[_vm._l((_vm.availableProducts),function(item){return _c('div',{key:item.id,staticClass:"left-right-margins hasBorderBottom",class:{'flex-item-42 hasDarkerBorderBottom': _vm.isPda && _vm.$screen.width > 640,
             'max-width-42': _vm.isPda && _vm.$screen.width > 1285,
             'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
             'max-width-47': _vm.isPda && _vm.$screen.width <= 1285 && _vm.$screen.width > 640 }},[_c('MenuItem',{attrs:{"isCategoryOpen":_vm.isCategoryOpen,"item":item,"membershipEnabledForRestaurant":_vm.membershipEnabledForRestaurant,"membershipPricesVisible":_vm.membershipPricesVisible,"isInteractiveCatalogue":_vm.isInteractiveCatalogue,"userHasMembership":_vm.userHasMembership,"storeIsHotel":_vm.storeIsHotel,"hideDescription":_vm.hideDescription,"hidePhotos":_vm.hidePhotos}})],1)}),(_vm.unavailableProducts.length > 0 && !_vm.clickedToSeeUnavailable)?_c('div',{staticClass:"left-right-margins",class:{'flex-item-42': _vm.isPda && _vm.$screen.width > 640,
             'max-width-42': _vm.isPda && _vm.$screen.width > 1285,
             'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
             'max-width-47': _vm.isPda && _vm.$screen.width <= 1285 && _vm.$screen.width > 640 }},[_c('div',{staticClass:"unavailable-items-container",on:{"click":_vm.showUnavailableItems}},[_c('div',{staticClass:"unavailable-items-button"},[_c('div',{staticClass:"unaivailble-items-title"},[_c('h3',[_vm._v(_vm._s(_vm.unavailableProducts.length)+" "+_vm._s(_vm.labels.menu.unavailanbleItems[_vm.language]))]),(!_vm.isCategoryOpen)?_c('h4',[_vm._v(_vm._s(_vm.labels.menu.servingHours[_vm.language])+" "+_vm._s(_vm.category.startTime.slice(0, -3))+"-"+_vm._s(_vm.category.endTime.slice(0, -3)))]):_vm._e()]),_c('div',{staticClass:"unaivailble-items-description"},[_c('div',[_vm._v(_vm._s(_vm.labels.menu.seeUnavailableItems[_vm.language]))])])])])]):_vm._e(),(_vm.unavailableProducts.length > 0 && _vm.clickedToSeeUnavailable)?_c('div',{class:{ 'two-items-flex-container-unavailable': _vm.isPda && _vm.$screen.width > 640 }},_vm._l((_vm.unavailableProducts),function(item){return _c('div',{key:item.id,staticClass:"left-right-margins hasBorderBottom",class:{'flex-item-42': _vm.isPda && _vm.$screen.width > 640,
               'max-width-42': _vm.isPda && _vm.$screen.width > 1285,
               'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
               'max-width-47': _vm.isPda && _vm.$screen.width <= 1285 && _vm.$screen.width > 640 }},[_c('MenuItem',{attrs:{"isCategoryOpen":_vm.isCategoryOpen,"item":item,"membershipEnabledForRestaurant":_vm.membershipEnabledForRestaurant,"membershipPricesVisible":_vm.membershipPricesVisible,"storeIsHotel":_vm.storeIsHotel,"happyHourDiscountPercentage":_vm.happyHourDiscountPercentage,"isInHappyHour":_vm.isInHappyHour,"hideDescription":_vm.hideDescription,"hidePhotos":_vm.hidePhotos}})],1)}),0):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }