

<template>
  <div id="categories-container" :class="{biggerTopDesktop: scrollPosition<=45}">
    <ChooseCategory></ChooseCategory>
    <div id="search-product-input-container">
      <input
        type="text"
        id="search-product-input"
        v-model="searchInput"
        @input="onSearchProductInput()"
        placeholder="Search"
      />
      <font-awesome-icon @click="clearSearchInput()" icon="times" id="clear-search-input" />
    </div>
    <div id="categories" :class="{smallerHeight: scrollPosition<=45}">
      <div class="category">
        <FoodCategory
          v-for="category in categories"
          v-bind:key="category.id"
          v-bind:category="category"
        ></FoodCategory>
      </div>
    </div>
  </div>
</template>

<script>
import FoodCategory from "./FoodCategory";
import ChooseCategory from "./ChooseCategory";

export default {
  name: "Categories",
  components: {
    FoodCategory,
    ChooseCategory,
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },
  },
  data() {
    return {
      searchInput: "",
      timer: undefined,
      scrollPosition: 0
    };
  },
  methods: {
    onSearchProductInput() {
      clearTimeout(this.timer)

      if (this.searchInput != null && this.searchInput != '' && this.searchInput.trim().length != 0) {
        this.timer = setTimeout(() => {
          this.$store.dispatch("setSearchProductsInput", this.searchInput);
        }, 530)
      } else {
        this.$store.dispatch("setSearchProductsInput", "");
      }

    },

    clearSearchInput() {
      clearTimeout(this.timer)
      this.searchInput = "";
      this.$store.dispatch("setSearchProductsInput", "");
    },
    watchSrollPosition() {
      this.scrollPosition = window.scrollY;
    }

  },
  mounted() {
    window.addEventListener("scroll", this.watchSrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchSrollPosition);
  },
};
</script>

<style scoped>
#categories-container {
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.16), 3px 3px 4px rgba(0, 0, 0, 0.23);
  background: white;
  border-radius: 18px 18px 10px 18px;
  position: fixed;
  left: 5vw;
  top: 9vh;
  width: 20vw;
  z-index: 201;
}

.biggerTopDesktop {
  top: 17vh !important;
}

#categories {
  padding: 0% 8% 100px 8%;
  color: gray;
  font-weight: bold;
  max-height: 73vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.smallerHeight {
  max-height: 65vh !important;
}

#categories::-webkit-scrollbar {
  width: 12px;
}

#categories::-webkit-scrollbar-thumb {
  background-color: #3b3838;
  border-radius: 25px;
  border: 1.9px solid white;
  z-index: 0;
}

#search-product-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding-left: 1px;
  background: #f9f9f9;
  width: 82%;
  height: 35px !important;
  margin-left: 10%;
  margin-bottom: 16px;
  margin-top: 10px;
}

#search-product-input {
  padding: 2px 3px 2px 6px;
  width: 93%;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500 !important;
}

#clear-search-input {
  width: 7%;
  color: #575757 !important;
  cursor: pointer;
}
</style>



