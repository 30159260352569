<template>
  <svg
    id="Layer_1"
    :style="emblemStyle"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 500 500"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="194.09968"
        y1="160.78651"
        x2="354.96376"
        y2="321.65059"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#198b4a" />
        <stop offset="1" stop-color="#198b4a" />
      </linearGradient>
    </defs>
    <path
      d="M232.684,274.89014l-.00024,0,.18341-26.78521c.90663-132.40518,108.9772-239.00581,241.38237-238.09918h.00024L474.06632,36.791C473.15969,169.19616,365.08912,275.79678,232.684,274.89014Zm-93.1856-12.3863A113.74808,113.74808,0,1,0,253.24643,376.25192,113.74808,113.74808,0,0,0,139.49835,262.50384Z"
      style="fill: url(#linear-gradient)"
    />
  </svg>
</template>

<script>
export default {
  props: ["emblemStyle"]
};
</script>

<style>
</style>